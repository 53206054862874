.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.top-tags {
  bottom: auto;
  top: 35px;
}

.tags {
  color: #ffd700;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore', cursive;

  @media(min-width: 320px)and(max-width:375px){
    left: 24%;
  }
  @media(min-width: 375px)and(max-width:425px){
    left: 23%;
  }
  @media(min-width: 425px)and(max-width:768px){
    left: 20%;
  }
  @media(min-width: 768px)and(max-width:1024px){
    left: 11%;
  }
}

.bottom-tag-html {
  margin-left: -20px;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.container.about-page{
  
  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ffd700;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    @media(min-width: 320px)and(max-width:375px){
      font-size: 47px;
      left:2px;
      text-align: justify;
    }
    @media(min-width: 425px)and(max-width:768px){
      font-size: 53px; 
      text-align: justify;
    }
    @media(min-width: 768px)and(max-width:1024px){
      font-size: 60px; 
      text-align: justify;
    }

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -10px;
      left: -10px;
      opacity: 0.6;
      line-height: 18px;

      @media(min-width:320px)and(max-width:375px){
        top: 10%;
      }
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: -30px;
      bottom: -20px;
      margin-left: 20px;
      opacity: 0.6;
    }
      
    } 
  }
   
  p {
    font-size: 13px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 300;
    max-width: fit-content;
    animation: pulse 1s;
    &:nth-of-type(1) {
      animation-delay: 1.1s;

      @media(min-width:425px)and(max-width:768px){
        margin-bottom: 24px;
      }
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;

      @media(min-width:425px)and(max-width:768px){
        margin-bottom: 24px;
      }
    }
    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }

    @media(min-width:375px)and(max-width:425px){
      font-size: 14px;
    }
    @media(min-width:425px)and(max-width:768px){
      font-size: 15px;
      letter-spacing: 1px;
    }
  }

.container.portfolio-page
h1
  {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ffd700;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -10px;
      left: -10px;
      opacity: 0.6;
      line-height: 18px;

      @media(min-width:320px)and(max-width:375px){
        top: 10%;
      }
      @media(min-width:425px)and(max-width:768px){
        top: 10%;
      }
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: -30px;
      bottom: -20px;
      margin-left: 20px;
      opacity: 0.6;
    }
} 
  
.container.contact-page

h1
  {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ffd700;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    @media(min-width:320px)and(max-width:375px){
      margin-left: 28px;
      margin-top: 25px;
      font-size: 41.5px;
    }
    @media(min-width:375px)and(max-width:425px){
      margin-left: 15px;
      margin-top: 15px;
    }
    @media(min-width:425px)and(max-width:768px){
      margin-left: 15px;
      margin-top: 15px;
    }
    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -10px;
      left: -10px;
      opacity: 0.6;
      line-height: 18px;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: -30px;
      bottom: -20px;
      margin-left: 20px;
      opacity: 0.6;
    }
}
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;

    @media(min-width:320px)and(max-width:375px){
      left: 24%;
      width: 74%;
    }
    @media(min-width:375px)and(max-width:425px){
      left: 24%;
      width: 74%;
    }
    @media(min-width:425px)and(max-width:768px){
      left: 24%;
      width: 76%;
      top:53%;
    }
    @media(min-width:768px)and(max-width:1024px){
      left: 14%;
      width: 45%;
      top:54%;
    }
  }
    
  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
