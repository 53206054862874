.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;

  svg {
    width: 100%;
    height: auto;
    bottom: 0;
    transform: rotateZ(30deg) !important;
  }

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    opacity: 1;
    transform: rotateZ(30deg) !important;
    z-index: 1;

    @media(min-width:320px)and(max-width:375px){
      opacity: 0.45;
      z-index: -1;
      top: 25%;
      left: 57%;
      width: 46.5%;
    }

    @media(min-width:375px)and(max-width:425px){
      opacity: 0.45;
      z-index: -1;
      top: 2%;
      left: 63%;
      width: 42.5%;
    }
    @media(min-width:425px)and(max-width:768px){
      opacity: 0.6;
      z-index: -1;
      top: 0;
      left: 57%;
      width: 49.5%;
    }
    @media(min-width:768px)and(max-width:1024px){
      opacity: 0.6;
      z-index: -1;
      top: 1%;
      left: 43%;
      width: 72.5%;
    }
  }
  @media(max-width:768px){
    z-index: -1;
    opacity: 0.8;
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}