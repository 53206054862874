.nav-bar {
    background: #181818;
    width: 70px;
    height: 100%;
    position: absolute;
    top:0;
    z-index: 3;
    min-height: 500px;

    .logo {
        display: block;
        padding: 8px 0;
        
        img {
         display: block;
         margin: 8px auto;
         width: 45px;
         height:auto;
         
         &.sub-logo {
             width: 50px;
         }
        }
        
    }
    
 nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
        font-size: 22px;
        color: #4d4d4e;
        display: block;
        line-height: 51px;
        height: 51px;
        position: relative;
        text-decoration: none;

        i {
            transition: all 0.3s ease-out;
        }

        &:hover {
            color: #ffd700;

            svg {
                opacity: 0;
            }

            &:after {
                opacity:1 ; 
            }
        }
    

    &::after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
    }


    &:first-child {
        &::after {
            content: 'HOME';
        }
    }
}


    a.about-link {
        &::after {
            content: 'ABOUT';
        }
    }

    a.contact-link {
        &::after {
            content: 'CONTACT';
        }
    }

    a.portfolio-link {
        &:after {
          content: 'PORTFOLIO';
        }
      }
      
    a.active {
        svg {
            color: #ffd700;
        }
    }
 }
  ul {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: block;
      padding: 0;
      list-style: none;
      text-align: center;
      margin: 0;

      li {
          a {
              padding: 7px ;
              display: block;
              font-size: 15px ;
              line-height: 16px;

              &:hover svg {
                  color: #ffd700;
              }

              @media(max-width:768px){
                font-size: 20px;
              }
          }
      }
  }
  @media(min-width:320px)and(max-width:375px){
    height: 100vh;
}
  @media(min-width:375px)and(max-width:425px){
    height: 100vh;
}
  @media(min-width:425px)and(max-width:768px){
    height: 102vh;
}
 
// @media screen and (min-width:320px) and (max-width: 425px){
//     height: 94vh;
// }
}