.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;

        @media(min-width:320px)and(max-width:375px){
            width: 95%;
            margin-left: auto;
            font-size: 44px;
          }
        @media(min-width:375px)and(max-width:425px){
            width: 89%;
            margin-left: auto;
          }
        @media(min-width:425px)and(max-width:768px){
            width: 89%;
            margin-left: auto;
            font-size: 60px;
          }
        @media(min-width:768px)and(max-width:1024px){
            width: 94%;
            margin-left: 35px;
            font-size: 63px;
          }
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;

        @media(min-width:320px)and(max-width:375px){
            display: block;
            padding-bottom: 100px;
            width: 120%;
          }
        @media(min-width:375px)and(max-width:425px){
            display: block;
            padding-bottom: 100px;
            width: 119%;
          }
        @media(min-width:425px)and(max-width:768px){
            display: block;
            padding-bottom: 100px;
            width: 118%;
          }
        @media(min-width:768px)and(max-width:1024px){
            display: grid;
            grid-template-columns: repeat(2,1fr);
            padding-bottom: 100px;
            width: 108%;
          }
    }

    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);

        @media(min-width:320px)and(max-width:375px){
            max-width: 92%;
            height: 280px;
            margin-bottom:20px ;
        }
        @media(min-width:375px)and(max-width:425px){
            max-width: 90%;
            height: 300px;
            margin-bottom:21px ; 
        }
        @media(min-width:425px)and(max-width:768px){
            max-width: 90%;
            height: 300px;
            margin-bottom:21px ; 
        }
        
        
        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;

            
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;

            @media(min-width:320px)and(max-width:375px){
            font-size: 13px;
            }
            @media(min-width:425px)and(max-width:768px){
            font-size: 16px;
            }
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ffd700;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #ffd700;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #ffd700, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
    .image-box:hover {
        box-shadow: 0px 5px 7px 2px #ffd700;
    }
}